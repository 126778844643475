/* eslint-disable @next/next/no-sync-scripts */

import Head from "next/head";

import { useSession } from "next-auth/react";
import Loader from "../components/Loader";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { UserIntialState } from "../redux/reducers/user";
import { insertStaffData, staffDataValue } from "../redux/reducers/staff";
import { useDispatch } from "react-redux";
import { getStorageItem } from "../functions/localStorage";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";

export default function Home() {
  const route = useRouter();
  const { asPath: path } = route;
  const dispatch = useDispatch();
  const { status, data: session }: any = useSession();

  const user = useSelector<RootState>((state) => state.user) as UserIntialState;

  if (status === "loading") return <Loader />;

  if (status === "unauthenticated" || (!session?.user.id && !user.password)) {
    if (path.includes("admin")) {
      route.push(`/admin/signin`);
    } else {
      route.push(`/signin`);
    }
  } else {
    if (user.isAdmin) route.push(`/backoffice`);
    if (!user.isAdmin) {
      const staff = localStorage.getItem("staff_data");
      if (staff) {
        const staffDataObject: staffDataType = JSON.parse(staff);
        if (staffDataObject?.id) {
          dispatch(insertStaffData(staffDataObject));
          route.push(`/staff`, undefined, {
            locale: user.company?.language || "th",
          });
        }
      } else {
        route.push("/staff_signin", undefined, {
          locale: user.company?.language,
        });
      }
    }

    //route.push("/staff", undefined, { locale: user.company?.language });
  }
  //route.push(`/backoffice`);

  return (
    <>
      <Head>
        <title>Innorder POS</title>
        <meta name="description" content="Innorder Point of Sale software" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="noindex,nofollow" />
        <meta name="robots" content="nositelinkssearchbox" />
        <link rel="icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
      </Head>
    </>
  );
}
